import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import GroupedMonthlyPriceChart from './GroupedMonthlyPriceChart';
import { SectionHeader, IconAlternate } from 'components/molecules';

class MonthlyPricesChartControl extends Component {

    
    constructor(props) {
        super(props);
        this.data = props.data;
        this.config = props.config;
        this.state = { width: 0, height: 0,  maxMonths: 12, chartHeight: this.config.height }
        
        this.state.selectedVendors = this.config.vendors.map( v=> { return { vendor:v, checked:true } } )
    }

    vendorChecked = (vendor) => {
        return this.vendorChecked[vendor];
    }

    vendorChanged = (vendor) => {
        console.log( vendor );
        this.vendorChecked[vendor] = !this.vendorChecked[vendor];
    }

    updateDimensions = () => {
        this.state.width = window.innerWidth;
        this.state.height = window.innerHeight;

        if( this.state.width <= 1024 )
        {
            this.state.maxMonths = 6;
            this.state.chartHeight = this.state.height - 300;
            this.config.priceRange = this.config.mobilePriceRange;
        }
        else
        {
            this.state.maxMonths = 12;
            this.state.chartHeight = this.config.height;
            this.config.priceRange = this.config.desktopPriceRange;
        }
        

        //TODO: Could update the # of bars or whatever based on width here I guess

        this.setState( this.state );
      };

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {

        if( this.state.width == 0 ) this.updateDimensions();
        
        let me = this;

        return (
            <section>
                <div style={{ display:"flex", flexDirection:"column", flexWrap: "wrap", paddingTop: "2em"}}>
                <SectionHeader 
                    titleVariant='h3' 
                    title='Compare Cloud Storage Prices' 
                    subtitleVariant='h4'
                    subtitle='Average WindRate savings against list prices'    
                    fadeUp 
                />

                    <div style={{ display:"flex", flexDirection:"row", flexWrap: "wrap", justifyContent: "center", maxWidth: "100%", height: this.state.chartHeight}}>

                        <section style={{ width: "100%", height:"100%", maxWidth: "100%"}}>
                            <GroupedMonthlyPriceChart config={this.config} maxMonths={this.state.maxMonths} priceData={this.data} selectedVendors={this.state.selectedVendors.filter( v => v.checked == true ).map( v => v.vendor )}/>
                        </section>
                    </div>
                    
                    <div style={{display:"flex", flexDirection:"row", flexWrap: "wrap", justifyContent: "center", maxWidth: "100%"}}>
                            {this.state.selectedVendors.map( el =>
                            (
                                <FormControlLabel
                                    control=
                                    {
                                        <Checkbox checked={el.checked} onChange={
                                            function () {

                                                let curData = me.state.selectedVendors,
                                                id = curData.findIndex(function(elem){ return elem.vendor == el.vendor});

                                                if( curData[id].checked && me.state.selectedVendors.filter( v => v.checked ).length <= 1 ) return;
                    
                                                curData[id].checked = !curData[id].checked;
                    
                                                me.state.selectedVendors = curData;
                                                me.setState(me.state);
                                            }}
                                        />
                                    } 
                                label={ <Typography style={{color:this.config.vendorColors[el.vendor], fontWeight:700}}>{this.config.vendorLabels[el.vendor]}</Typography>}/>
                            ))}
                    </div>
                </div>
            </section>
        )
    }
}

export default MonthlyPricesChartControl;