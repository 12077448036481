import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Section, SectionAlternate } from 'components/organisms';
import MonthlyPricesChartControl from 'components/windrate/Charts/MonthlyPricesChartControl';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
}));


const monthlyPricesChartExampleConfiguration =
{
    priceRange: [12,26],
    desktopPriceRange: [12,26],
    mobilePriceRange: [12,26],

    fontSize: 12,
    height: "700px",

    vendors : [ "aws", "google", "windrate" ],

    vendorColors : 
    {
        "aws" : "orange",
        "google" : "#4285F4",
        "azure" : "#007fff",
        "windrate" : "#ae36f1"
    },

    vendorLabels : 
    {
        "aws" : "AWS S3",
        "google" : "Google Cloud",
        "azure" : "Microsoft Azure",
        "windrate" : "WindRate Average"
    }
}

const realPricesChartExampleData = [
    {
      "name": "Apr 2021",
      "prices": [
        {
          "vendor": "azure",
          "price": 23
        },
        {
          "vendor": "aws",
          "price": 23
        },
        {
          "vendor": "ibm",
          "price": 22
        },
        {
          "vendor": "google",
          "price": 23
        },
        {
          "vendor": "windrate",
          "price": 15
        }
      ]
    },
    {
      "name": "May 2021",
      "prices": [
        {
          "vendor": "azure",
          "price": 23
        },
        {
          "vendor": "aws",
          "price": 23
        },
        {
          "vendor": "google",
          "price": 23
        },
        {
          "vendor": "windrate",
          "price": 15
        }
      ]
    },
    {
      "name": "Jun 2021",
      "prices": [
        {
          "vendor": "azure",
          "price": 23
        },
        {
          "vendor": "aws",
          "price": 23
        },
        {
          "vendor": "ibm",
          "price": 22
        },
        {
          "vendor": "google",
          "price": 23
        },
        {
          "vendor": "windrate",
          "price": 17
        }
      ]
    },
    {
      "name": "Jul 2021",
      "prices": [
        {
          "vendor": "azure",
          "price": 23
        },
        {
          "vendor": "aws",
          "price": 23
        },
        {
          "vendor": "ibm",
          "price": 22
        },
        {
          "vendor": "google",
          "price": 23
        },
        {
          "vendor": "windrate",
          "price": 18.58
        }
      ]
    },
    {
      "name": "Aug 2021",
      "prices": [
        {
          "vendor": "azure",
          "price": 23
        },
        {
          "vendor": "aws",
          "price": 23
        },
        {
          "vendor": "ibm",
          "price": 22
        },
        {
          "vendor": "google",
          "price": 23
        },
        {
          "vendor": "windrate",
          "price": 15.99
        }
      ]
    },
    {
      "name": "Sep 2021",
      "prices": [
        {
          "vendor": "azure",
          "price": 23
        },
        {
          "vendor": "aws",
          "price": 23
        },
        {
          "vendor": "ibm",
          "price": 22
        },
        {
          "vendor": "google",
          "price": 23
        },
        {
          "vendor": "windrate",
          "price": 15.99
        }
      ]
    },
    {
      "name": "Oct 2021",
      "prices": [
        {
          "vendor": "azure",
          "price": 23
        },
        {
          "vendor": "aws",
          "price": 23
        },
        {
          "vendor": "google",
          "price": 20
        },
        {
          "vendor": "ibm",
          "price": null
        },
        {
          "vendor": "windrate",
          "price": 15
        }
      ]
    },
    {
      "name": "Nov 2021",
      "prices": [
        {
          "vendor": "azure",
          "price": 23
        },
        {
          "vendor": "aws",
          "price": 23
        },
        {
          "vendor": "google",
          "price": 20
        },
        {
          "vendor": "ibm",
          "price": 22
        },
        {
          "vendor": "windrate",
          "price": 18.58
        }
      ]
    },
    {
      "name": "Dec 2021",
      "prices": [
        {
          "vendor": "azure",
          "price": 23
        },
        {
          "vendor": "aws",
          "price": 23
        },
        {
          "vendor": "google",
          "price": 20
        },
        {
          "vendor": "ibm",
          "price": 22
        },
        {
          "vendor": "windrate",
          "price": 14.47
        }
      ]
    },
    {
      "name": "Jan 2022",
      "prices": [
        {
          "vendor": "azure",
          "price": 23
        },
        {
          "vendor": "aws",
          "price": 23
        },
        {
          "vendor": "google",
          "price": 20
        },
        {
          "vendor": "ibm",
          "price": 22
        },
        {
          "vendor": "windrate",
          "price": 16.47
        }
      ]
    },
    {
      "name": "Feb 2022",
      "prices": [
        {
          "vendor": "azure",
          "price": 23
        },
        {
          "vendor": "aws",
          "price": 23
        },
        {
          "vendor": "google",
          "price": 20
        },
        {
          "vendor": "ibm",
          "price": 22
        },
        {
          "vendor": "windrate",
          "price": 17.9
        }
      ]
    }
  ]

const monthlyPricesChartExampleData = [
    {
        name: "Jan 2021",

        prices: [
            { vendor: "aws", price: 23.0 },
            { vendor: "google", price: 21.0 },
            { vendor: "windrate", price: 19.0 }
        ]
    },

    {
        name: "Feb 2021",

        prices: [
            { vendor: "aws", price: 23.0 },
            { vendor: "google", price: 22.0 },
            { vendor: "windrate", price: 17.0 }
        ]
    },

    {
        name: "Mar 2021",

        prices: [
            { vendor: "aws", price: 23.0 },
            { vendor: "google", price: 23.0 },
            { vendor: "windrate", price: 15.0 }
        ]
    },

    {
        name: "Apr 2021",

        prices: [
            { vendor: "aws", price: 23.0 },
            { vendor: "google", price: 21.0 },
            { vendor: "windrate", price: 19.0 }
        ]
    },

    {
        name: "May 2021",

        prices: [
            { vendor: "aws", price: 23.0 },
            { vendor: "google", price: 22.0 },
            { vendor: "windrate", price: 17.0 }
        ]
    },

    {
        name: "Jun 2021",

        prices: [
            { vendor: "aws", price: 23.0 },
            { vendor: "google", price: 23.0 },
            { vendor: "windrate", price: 15.0 }
        ]
    },

    {
        name: "Jul 2021",

        prices: [
            { vendor: "aws", price: 23.0 },
            { vendor: "google", price: 22.0 },
            { vendor: "windrate", price: 17.0 }
        ]
    },

    {
        name: "Aug 2021",

        prices: [
            { vendor: "aws", price: 23.0 },
            { vendor: "google", price: 23.0 },
            { vendor: "windrate", price: 15.0 }
        ]
    },

    {
        name: "Sep 2021",

        prices: [
            { vendor: "aws", price: 23.0 },
            { vendor: "google", price: 21.0 },
            { vendor: "windrate", price: 19.0 }
        ]
    },

    {
        name: "Oct 2021",

        prices: [
            { vendor: "aws", price: 23.0 },
            { vendor: "google", price: 22.0 },
            { vendor: "windrate", price: 17.0 }
        ]
    },

    {
        name: "Nov 2021",

        prices: [
            { vendor: "aws", price: 23.0 },
            { vendor: "google", price: 23.0 },
            { vendor: "windrate", price: 15.0 }
        ]
    },

    {
        name: "Dec 2021",

        prices: [
            { vendor: "aws", price: 23.0 },
            { vendor: "google", price: 23.0 },
            { vendor: "windrate", price: 15.0 }
        ]
    }
  ]

const CompareStoragePrices = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Section>
                <MonthlyPricesChartControl data={realPricesChartExampleData} config={monthlyPricesChartExampleConfiguration}></MonthlyPricesChartControl>
            </Section>
        </div>
    );
};

export default CompareStoragePrices;