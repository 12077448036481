import React from 'react';
import NoSsr from '@material-ui/core/NoSsr';
const Plot = require('react-plotly.js').default;

function GroupedMonthlyPriceChart({ priceData, config, selectedVendors, maxMonths }) {

    let validMonths = priceData.filter( p => p.prices );

        let plotdata = selectedVendors.map( vendor => 
        {
            let validMonths = priceData.slice(-maxMonths).filter( p => p.prices.some( pr => pr.vendor === vendor ) );
                    
            return ({
                type: 'bar', 

                name:  config.vendorLabels[vendor],
                
                x: validMonths.map( month => month.name ), 
                y: validMonths.map( month => month.prices.filter( pr => pr.vendor === vendor )[0].price ),
                hovertemplate: '%{y}',

                marker: {
                    color: config.vendorColors[vendor]
                } 
            });
        });

        let vendor = "windrate";
        let validMonthsForWindrate = priceData.slice(-maxMonths).filter( p => p.prices.some( pr => pr.vendor === vendor ) );
        
        plotdata.push(
            {
                type: 'line', 

                name:  config.vendorLabels[vendor],
                
                x: validMonthsForWindrate.map( month => month.name ), 
                y: validMonthsForWindrate.map( month => month.prices.filter( pr => pr.vendor === vendor )[0].price ),
                hovertemplate: '%{y}',

                marker: {
                    color: config.vendorColors[vendor]
                } 
            });

        return (
            <NoSsr>
                <Plot
                    style={{ width: '100%', height: '100%' }}

                    config={{

                        displayModeBar: false
                    }}

                    data={plotdata}

                    layout={{
                        
                                barmode: 'group',

                                showlegend: false,
                                autosize: true,
                                font: {size: config.fontSize},
                                
                                margin: {
                                    l: "4em",
                                    r: "4em",
                                    b: "8em",
                                    t: 0,
                                    pad: 0
                                },

                                title: priceData.name,
                                
                                xaxis:
                                {
                                    fixedrange: true
                                },

                                yaxis:
                                {
                                    range: config.priceRange,
                                    showgrid: true,
                                    tickformat: '$',
                                    tick0: 2,
                                    dtick: 2,
                                    fixedrange: true
                                }
                            }}
                />  
            </NoSsr>
      );
  }

export default GroupedMonthlyPriceChart;