import React from 'react';
import CompareStoragePrices from 'views/CompareStoragePrices';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';
import { Helmet } from 'react-helmet';

const title = 'Compare Storage Prices';

const ChartExamplePage = () => {
  return (
    <>
    <Helmet
      defaultTitle="WindRate | #1 Cloud Storage Pricing Engine"
      title={title}
      titleTemplate="%s | WindRate.com"
    />    
    <WithLayout
      component={CompareStoragePrices}
      layout={Main}
    />
    </>
  )
};

export default ChartExamplePage;
